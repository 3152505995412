<template>
  <!-- OPERATIONS UNIT READ ONLY IT SELF DATA -->
  <div v-if="$role(['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic'], $store.state.user.role)">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex jutify-space-between col-sm-2 py-4 px-2">
        <v-card :class="$role(['logistic'], $store.state.user.role) ? 'primary' : 'grey'" class="d-flex align-center justify-center px-4 caption font-weight-bold py-2" dark outlined style="width: 49%;" @click.prevent="$role(['logistic'], $store.state.user.role) ? PRODUCT_VIEW() : false">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Product
        </v-card>
        <v-card :class="$role(['logistic', 'operation_unit'], $store.state.user.role) ? 'purple' : 'grey'" class="d-flex align-center justify-center px-4 caption font-weight-bold ml-1 py-2" dark outlined style="width: 49%;" @click.prevent="$role(['logistic', 'operation_unit'], $store.state.user.role) ? StockRequestOUShow() : false">
          <v-icon small class="mr-2">
            mdi-chart-donut
          </v-icon>
          Stock
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex jutify-space-between col-sm-2 py-4 px-2">
        <v-card :class="$role(['logistic'], $store.state.user.role) ? 'green' : 'grey'" class="d-flex align-center justify-center px-4 caption font-weight-bold mr-1 py-2" dark outlined style="width: 49%;" @click.prevent="$role(['logistic'], $store.state.user.role) ? ExportExcel(true) : false">
          <v-icon small class="mr-2">
            mdi-microsoft-excel
          </v-icon>
          Export
        </v-card>
        <v-card :class="$role(['logistic'], $store.state.user.role) ? 'green' : 'grey'" class="d-flex align-center justify-center px-4 caption font-weight-bold ml-1 py-2" dark outlined style="width: 49%;" @click.prevent="$role(['logistic'], $store.state.user.role) ? ExportExcel('all') : false">
          <v-icon small class="mr-2">
            mdi-microsoft-excel
          </v-icon>
          All
        </v-card>
      </v-col>
      <v-col cols="6" class="white col-sm-2">
        <div class="px-0 px-sm-4 py-2 py-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            placeholder="Search product"
            prepend-inner-icon="mdi-magnify"
            append-icon="mdi-barcode"
          />
        </div>
      </v-col>
      <v-col cols="6" class="col-sm-2 white">
        <div class="px-0 px-sm-4 py-2 py-sm-4">
          <v-select
            v-model.number="storeUnit"
            :disabled="$store.state.user.role === 'operation_unit'"
            :items="[{ name: 'All', id: 0 }].concat($store.state.storeData)"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            label="Operations Unit"
          />
        </div>
      </v-col>
      <v-col cols="6" class="col-sm-1 white">
        <div class="px-0 px-sm-4 py-2 py-sm-4">
          <v-select
            v-model.number="productType"
            :items="[{ name: 'Parts', value: 0 }, { name: 'Services', value: 1 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            label="Type"
            :disabled="!$role(['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic'], $store.state.user.role)"
          />
        </div>
      </v-col>
      <v-col cols="6" class="white col-sm-1">
        <div class="px-0 px-sm-4 py-2 py-sm-4">
          <v-select
            v-model="productStatus"
            :disabled="!$role(['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic'], $store.state.user.role)"
            :items="[{ name: 'All Status', value: '' }, { name: 'Active', value: 1 }, { name: 'Not Active', value: 0 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            label="Status"
          />
        </div>
      </v-col>
      <v-col cols="12" class="white col-sm-2">
        <div class="px-0 px-sm-4 py-2 py-sm-4">
          <v-autocomplete
            v-model.number="customerSelected"
            :search-input.sync="customerQuery"
            :items="[{ detailname: 'Default Price List', id: 0 }].concat(customerResult)"
            item-value="id"
            item-text="detailname"
            outlined
            dense
            hide-details
            clearable
            autocomplete="none"
            append-icon="mdi-download-box"
            class="red lighten-4"
            label="Download Price List"
            @click:append="PRICE_LIST_DOWNLOAD()"
          />
          <!-- <v-btn depressed @click.prevent="$store.dispatch('PRICE_LIST_DOWNLOAD', 0)">
            DOWNLOAD
          </v-btn> -->
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 pt-sm-4">
        <v-data-table
          dense
          :headers="productType ? headers.filter(r => (r.text === 'Product' || r.text === 'Price' || r.text === 'Opsi')) : headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [10, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`header.stock`]="{ header }">
            <div class="d-inline-flex justify-space-between align-center mr-1 lime darken-4 caption white--text rounded-pill px-1" style="width: calc(100% - 0px);">
              <span class="text-center" style="min-width: 25%;">Ops. Unit</span>
              <span class="text-center" style="min-width: 25%;">Warehouse</span>
              <span class="text-center" style="min-width: 25%;">{{ header.text }}</span>
            </div>
          </template>
          <template v-slot:[`item.stock`]="{ item }">
            <div :class="[(parseInt(item.stock_in) - parseInt(item.stock_out_trx) <= 0) ? 'red' : ((parseInt(item.stock_in) - parseInt(item.stock_out_trx) <= 10) ? 'orange' : 'green')]" class="white--text d-inline-flex flex-column flex-sm-row justify-space-between align-center mr-2 pa-2 rounded-lg my-1" style="width: calc(100% - 0px);">
              <span class="text-left c-pointer d-inline-flex flex-column align-start caption indigo lighten-1 pa-2 rounded grey--text text--lighten-2" style="min-width: 25%;line-height: 140%;" @click.prevent="$role(['logistic'], $store.state.user.role) ? ShowStock(item) : false">
                <span v-if="storeUnit">Ops. Unit: {{ ((parseInt(item.stock_ou_inhand) || 0) - (parseInt(item.stock_ou_outhand) || 0) - (parseInt(item.stock_ou_processed) || 0)) - parseInt(item.stock_out_trx || 0) }}</span>
                <span v-else>Ops. Unit: {{ ((parseInt(item.stock_ou_inhand) || 0) - (parseInt(item.stock_ou_outhand) || 0) - (parseInt(item.stock_ou_processed) || 0)) - parseInt(item.stock_out_trx || 0) }}</span>
                <span>Trx/Booked: {{ (parseInt(item.stock_ou_processed) || 0) }}</span>
              </span>
              <span v-if="storeUnit" class="text-center" style="min-width: 25%;">
                ~
              </span>
              <span v-else class="text-center" style="min-width: 25%;">
                {{ ((parseInt(item.stock_in) || 0)) - ((parseInt(item.stock_ou_inhand) || 0) - (parseInt(item.stock_ou_outhand) || 0)) }}
              </span>
              <span v-if="storeUnit" class="text-center" style="min-width: 25%;">~</span>
              <span v-else class="text-center" style="min-width: 25%;">{{ parseInt(item.stock_in) - parseInt(item.stock_out_trx) }}</span>
            </div>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <div>
              #{{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.sku`]="{ item }">
            <div style="max-width: 75px;">
              {{ item.sku }} {{ item.sku2 ? ', ' + item.sku2 : '' }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex justify-space-between align-center py-1" style="min-width: 100px;">
              <div class="d-flex flex-column">
                <v-btn depressed dark x-small :color="parseInt(item.product_is_service) ? 'orange' : 'indigo'" class="rounded text-capitalize caption" @click.prevent="$role(['logistic'], $store.state.user.role) ? PRODUCT_VIEW(item) : false">
                  <v-icon x-small class="mr-1">
                    {{ parseInt(item.product_is_service) ? 'mdi-account-wrench-outline' : 'mdi-select-group' }}
                  </v-icon>
                  {{ item.product_name }}
                </v-btn>
                <span :class="[parseInt(item.status) ? '' : 'red--text']" class="subtitle-2">{{ item.name }}</span>
              </div>
              <v-chip v-if="item.product_category" x-small color="primary" dark>
                {{ item.product_category || '' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <div style="min-width: 50px;">
              {{ $price(item.price) }}
            </div>
          </template>
          <template v-slot:[`item.stock_in`]="{ item }">
            <div class="d-flex align-center justify-space-between" style="min-width: 50px;">
              <v-chip small dark color="green">
                {{ !parseInt(storeUnit) ? parseInt(item.stock_in || 0) : (parseInt(item.stock_ou_inhand) || 0) }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.stock_out_trx`]="{ item }">
            <div class="d-flex align-center" style="min-width: 50px;">
              <v-chip small dark color="red">
                {{ parseInt(item.stock_out_trx || 0) }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['director', 'logistic'], $store.state.user.role)" :color="$role(['director', 'logistic'], $store.state.user.role) ? 'orange' : 'grey'" depressed small dark class="text-capitalize" @click.prevent="$role(['director', 'logistic'], $store.state.user.role) ? PRODUCT_ITEM_VIEW(item, 0, true) : false">
                <v-icon x-small class="mr-2">
                  mdi-pencil
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogProduct.show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="rounded-0">
        <v-toolbar
          dark
          color="primary"
          class="elevation-0"
        >
          <v-btn
            icon
            dark
            @click="dialogProduct.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ dialogProduct.data ? parseInt(dialogProduct.data.id) ? 'Update Product' : 'New Product' : 'Undefined' }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <!-- <v-btn
              dark
              text
              @click="dialogProduct.show = false"
            >
              Save
            </v-btn> -->
          </v-toolbar-items>
        </v-toolbar>
        <v-container v-if="dialogProduct.data">
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-6 pa-4">
              Product Detail
              <v-divider class="my-4" />
              <!-- <v-select
                v-model="dialogProduct.data.stores"
                :items="$store.state.storeData"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
                label="Aktifkan pada Toko :"
                class="mb-4"
                multiple
                persistent-hint
                :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
              ></v-select> -->
              <v-select
                v-model.number="dialogProduct.data.is_service"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Part', value: 0 },
                  { name: 'Service', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Product Type"
                class="mb-4"
                @change="dialogProduct.data.category = 'No Brand'"
              />
              <v-autocomplete
                v-if="!parseInt(dialogProduct.data.is_service)"
                v-model="dialogProduct.data.category"
                :items="[{ name: 'No Brand' }].concat($store.state.product.categories)"
                item-text="name"
                item-value="name"
                outlined
                dense
                hide-details
                label="Select Brand"
                class="mb-4"
                :disabled="!$role(['logistic'], $store.state.user.role)"
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <img :src="data.item.logo">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name" />
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-text-field
                v-model="dialogProduct.data.name"
                outlined
                dense
                hide-details
                label="Product Name"
                class="mb-4"
                :disabled="!$role(['logistic'], $store.state.user.role)"
              />
              <v-textarea
                v-model="dialogProduct.data.description"
                outlined
                dense
                hide-details
                label="Product Description"
                class="mb-4"
                :disabled="!$role(['logistic'], $store.state.user.role)"
              />
              <!-- <v-select
                v-model.number="dialogProduct.data.featured"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Tidak', value: 0 },
                  { name: 'Ya', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Tampilkan sebagai Produk Unggulan?"
                class="mb-4"
              /> -->
              <!-- <v-select
                v-model.number="dialogProduct.data.status"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Tidak', value: 0 },
                  { name: 'Ya', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Tampilkan pada Aplikasi?"
                class="mb-4"
              /> -->
              <!-- <Tags v-model="dialogProduct.data.tags" /> -->
              <!-- <v-select
                v-model.number="dialogProduct.data.featured"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Tidak', value: 0 },
                  { name: 'Ya', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Featured Product"
                class="mb-4"
                :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
              /> -->
              <!-- <v-select
                v-model.number="dialogProduct.data.status"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Tidak', value: 0 },
                  { name: 'Ya', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Tampilkan pada web ?"
                class="mb-4"
                :disabled="!$role(['manager', 'warehouse'], $store.state.user.role)"
              /> -->
            </v-col>
            <v-col cols="12" class="col-sm-6 pa-4">
              <div class="d-flex fill-width justify-space-between">
                Product Item/Variant
                <v-btn v-if="$role(['logistic'], $store.state.user.role)" color="orange" dark small depressed class="text-capitalize  caption rounded-0" @click.prevent="PRODUCT_ITEM_VIEW(null, -1)">
                  Add Variant
                </v-btn>
              </div>
              <v-divider class="mt-3 mb-4" />
              <div>
                <div v-for="(i, iI) in dialogProduct.items" :key="'item-' + iI" class="d-flex fill-width justify-space-between mb-4 pa-2 grey lighten-4">
                  <div class="d-flex">
                    <v-img
                      :src="$getImage(i.galleries)"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      style="width: 100px!important"
                    />
                    <div class="pl-4">
                      <div class="grey--text text-uppercase">
                        <v-icon class="mr-1">
                          mdi-barcode
                        </v-icon>
                        <span class="caption">
                          {{ i.sku }} {{ i.sku2 ? ', ' + i.sku2 : '' }}
                        </span>
                      </div>
                      <div class="font-weight-bold">
                        {{ i.name }}
                      </div>
                      <div class="font-weight-bold">
                        {{ $price(i.price) }}
                      </div>
                    </div>
                  </div>
                  <div v-if="$role(['logistic'], $store.state.user.role)">
                    <v-btn v-if="!i.id" icon color="red" small @click.prevent="dialogProduct.items.splice(iI, 1)">
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                    <v-btn icon color="blue" small @click.prevent="PRODUCT_ITEM_VIEW(i, iI)">
                      <v-icon>
                        mdi-pencil-circle
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col v-if="$role(['logistic'], $store.state.user.role)" cols="12" class="text-center">
              <v-divider class="mt-4 mb-8" />
              <v-btn :disabled="loadingProcess" depressed color="blue" dark class="text-capitalize" @click.prevent="PRODUCT_PROCESS()">
                {{ loadingProcess ? 'Please Wait' : 'Simpan' }}
               </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogProductItem.show"
      persistent
      max-width="840"
      scrollable
    >
      <v-card>
        <v-card-title v-if="dialogProductItem.data">
          {{ dialogProductItem.index > -1 || parseInt(dialogProductItem.data.id) ? 'Update Variant' : 'New Variant' }}
        </v-card-title>
        <v-divider />
        <v-card-text v-if="dialogProductItem.data" class="py-4" style="height: calc(100% - 102px);">
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-5">
              <v-text-field
                v-if="dialogProduct.show && dialogProduct.data ? !parseInt(dialogProduct.data.is_service) : !parseInt(dialogProductItem.data.product_is_service)"
                v-model="dialogProductItem.data.sku"
                outlined
                dense
                hide-details
                prepend-inner-icon="mdi-barcode"
                label="SKU"
                class="mb-4"
              />
              <v-text-field
                v-if="dialogProduct.show && dialogProduct.data ? !parseInt(dialogProduct.data.is_service) : !parseInt(dialogProductItem.data.product_is_service)"
                v-model="dialogProductItem.data.sku2"
                outlined
                dense
                hide-details
                prepend-inner-icon="mdi-barcode"
                label="SKU 2"
                class="mb-4"
              />
              <v-text-field
                v-model="dialogProductItem.data.name"
                outlined
                dense
                hide-details
                label="Variant Name"
                class="mb-4"
              />
              <v-text-field
                v-if="!parseInt(dialogProduct.data.is_service)"
                v-model="dialogProductItem.data.merk"
                outlined
                dense
                hide-details
                label="Merk"
                class="mb-4"
              />
              <v-text-field
                v-if="dialogProduct.show && dialogProduct.data ? !parseInt(dialogProduct.data.is_service) : !parseInt(dialogProductItem.data.product_is_service)"
                v-model.number="dialogProductItem.data.weight"
                outlined
                dense
                hide-details
                label="Weight/Volume"
                suffix="gram"
                class="mb-4"
                @keypress="$NumberOnly"
              />
              <!-- <Tags v-model="dialogProductItem.data.tags" /> -->
              <v-divider class="mb-4" />
              <v-text-field
                v-if="!parseInt(dialogProduct.data.is_service)"
                v-model.number="dialogProductItem.data.price_initial"
                :label="'Buy Price' + (parseInt(dialogProductItem.data.price_initial_updated) ? (' : ' + $price(parseInt(dialogProductItem.data.price_initial_updated)) + ' is latest price') : '')"
                outlined
                dense
                hide-details
                prefix="Rp"
                class="mb-4"
                @keypress="$NumberOnly"
              />
              <v-text-field
                v-model.number="dialogProductItem.data.price"
                outlined
                dense
                hide-details
                label="Default Sell Price"
                prefix="Rp"
                class="mb-4"
                @keypress="$NumberOnly"
              />
              <div v-if="parseInt(dialogProductItem.data.id)" class="pb-4 black--text" style="line-height: 120%;">
                <div class="d-flex fill-width justify-space-between align-center font-weight-bold pb-1">
                  # Special Pricing
                  <span v-if="$role(['director'], $store.state.user.role)" class="d-flex align-center c-pointer" @click.prevent="ShowPricing()">
                    <v-icon class="mr-1" small color="primary">
                      mdi-plus-circle
                    </v-icon>
                    Add
                  </span>
                </div>
                <div v-if="!dialogProductItem.pricing.length" class="px-3 py-2 grey--text">
                  No pricing
                </div>
                <div v-for="(pp, iPp) in dialogProductItem.pricing" :key="'item-pricing-' + iPp" class="d-flex align-center justify-space-between my-1 grey lighten-2 px-3 py-2 rounded">
                  <div>
                    {{ pp.customer_name }}
                    <div class="caption">
                      {{ pp.customer_role }}
                    </div>
                  </div>
                  <span class="d-flex align-center">
                    {{ $price(pp.price) }}
                    <v-icon v-if="$role(['director'], $store.state.user.role)" class="ml-2" small color="indigo" @click.prevent="ShowPricing(pp)">
                      mdi-pencil
                    </v-icon>
                  </span>
                </div>
              </div>
              <v-select
                v-model.number="dialogProductItem.data.status"
                outlined
                dense
                hide-details
                :items="[
                  { name: 'Tidak Aktif', value: 0 },
                  { name: 'Aktif', value: 1 }
                ]"
                item-text="name"
                item-value="value"
                label="Status"
                class="mb-4"
              />
            </v-col>
            <v-col v-if="dialogProduct.show && dialogProduct.data ? !parseInt(dialogProduct.data.is_service) : !parseInt(dialogProductItem.data.product_is_service)" cols="12" class="col-sm-7 px-0 px-sm-4">
              <v-icon small class="mr-2 ">
                mdi-image-multiple
              </v-icon>
              Variant Gallery
              <v-divider class="mt-2" />
              <v-row class="py-2 px-1">
                <v-col cols="6" class="col-sm-4 pa-2" style="position: relative;" @click.prevent="UploadImage()">
                  <v-img
                    aspect-ratio="1"
                    class="blue lighten-4 fill-width"
                  />
                  <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center" style="position: absolute;top: 0px;left:0px;">
                    <span v-if="loadingImageUpload">
                      uploading ...
                    </span>
                    <span v-else>
                      + Add Image<br>
                      1:1
                    </span>
                  </div>
                </v-col>
                <v-col v-for="(g, iImg) in dialogProductItem.data.galleries" :key="'gallery-' + iImg" cols="6" class="col-sm-4 pa-2 gmj-gallery" style="position: relative;" >
                  <v-img
                    :src="g"
                    aspect-ratio="1"
                    class="grey lighten-3 fill-width"
                  />
                  <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center gmj-gallery-action" style="position: absolute;top: 0px;left:0px;">
                    <span>
                      <v-btn x-small icon color="purple" class="mr-1" @click.prevent="changeGallery(iImg)">
                        <v-icon small color="blue">
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                      <v-btn x-small icon color="red" class="ml-1" @click.prevent="removeGallery(iImg)">
                        <v-icon small color="red">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="px-0 pt-2 mt-2">
              <v-textarea
                v-model="dialogProductItem.data.description"
                outlined
                dense
                rows="3"
                hide-details
                label="Variant Description"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="px-0 px-sm-4 pt-2 mt-2 grey lighten-3">
              <div class="d-flex fill-width align-center pt-2 pb-3 font-weight-bold">
                <v-icon small class="mr-2">
                  mdi-file-tree-outline
                </v-icon>
                Pricing Schema
              </div>
              <v-row no-gutters class="pb-4">
                <!--
                  [{ type: discount/gift, customer: (0: default/1: special), platform: offline/online, minimum_quantity: 1, discount: 0, gift: '' }]
                -->
                <v-col cols="12" class="col-sm-4">
                  <!-- :items="[{ name: 'Discount', value: 'discount' }, { name: 'Hadiah (Gift)', value: 'gift' }]" -->
                  <v-autocomplete
                    v-model="pBerjenjang.type"
                    :items="[{ name: 'Discount', value: 'discount' }]"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    hide-details
                    label="Type"
                    class="mb-4 grey lighten-4"
                  />
                  <!-- <v-autocomplete
                    v-model="pBerjenjang.platform"
                    :items="[{ name: 'Semua (Online & Offline)', value: '' }, { name: 'Offline', value: 0 }, { name: 'Online', value: 1 }]"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    hide-details
                    label="Platform"
                    class="mb-4 grey lighten-4"
                  /> -->
                  <v-autocomplete
                    v-model="pBerjenjang.customer"
                    :items="[{ name: 'All', value: '' }, { name: 'Regular Customer', value: 0 }, { name: 'Business', value: 1 }]"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    hide-details
                    label="Customer Type"
                    class="mb-4 grey lighten-4"
                  />
                  <v-text-field
                    v-model="pBerjenjang.minimum_quantity"
                    outlined
                    dense
                    hide-details
                    label="Minimum Order Quantity"
                    suffix="SKU"
                    class="mb-4 grey lighten-4"
                    @keypress="$NumberOnly"
                  />
                  <div :class="[pBerjenjang.type === 'discount' ? 'orange' : pBerjenjang.type === 'gift' ? 'brown' : '']" class="pa-2 mb-4">
                    <v-text-field
                      v-if="pBerjenjang.type === 'discount'"
                      v-model.number="pBerjenjang.discount"
                      outlined
                      dense
                      hide-details
                      label="Discount"
                      prefix="Rp"
                      dark
                      @keypress="$NumberOnly"
                    />
                    <v-text-field
                      v-else-if="pBerjenjang.type === 'gift'"
                      v-model="pBerjenjang.gift"
                      outlined
                      dense
                      hide-details
                      dark
                      label="Keterangan Gift"
                      prepend-inner-icon="mdi-gift-open-outline"
                    />
                  </div>
                  <div :class="[pBerjenjang.indexSelected > -1 ? 'justify-space-between' : 'justify-end']" class="d-flex fill-width">
                    <v-btn v-if="pBerjenjang.indexSelected > -1" depressed dark small color="red" class="rounded-0" @click.prevent="SYNC_PBJ()">
                      Batal
                    </v-btn>
                    <v-btn :color="pBerjenjang.indexSelected > -1 ? 'indigo' : 'purple'" depressed small dark class="rounded-0" @click.prevent="PROSES_PBJ()">
                      {{ pBerjenjang.indexSelected > -1 ? 'Update' : 'Create' }}
                      <v-icon x-small class="ml-1">
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" class="col-sm-8 pl-0 pl-sm-4">
                  <div class="table-pbj">
                    <table class="fill-width">
                      <thead>
                        <tr>
                          <td class="blue lighten-4">Skema</td>
                          <td class="blue lighten-4">Customer Type</td>
                          <td class="blue lighten-4">Min.Qty</td>
                          <td class="blue lighten-4">Opsi</td>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(pb, iPb) in dialogProductItem.data.price_berjenjang">
                          <tr v-if="pBerjenjang.indexSelected > -1 ? pBerjenjang.indexSelected === iPb : true" :key="iPb" :class="[pBerjenjang.indexSelected === iPb ? 'pb-active' : '']">
                            <td>
                              {{ pb.type }}
                              <v-chip v-if="pb.type === 'discount'" color="orange" dark x-small class="ml-1" style="font-size: 10px;">
                                {{ $price(pb.discount) }}
                              </v-chip>
                              <v-chip v-else-if="pb.type === 'gift'" color="brown" dark x-small class="ml-1" style="font-size: 10px;">
                                {{ pb.gift }}
                              </v-chip>
                            </td>
                            <td>{{ (pb.customer === '' ? 'All' : parseInt(pb.customer) === 0 ? 'Regular' : 'Business') }}</td>
                            <td>{{ isNaN(parseInt(pb.minimum_quantity)) ? 0 : parseInt(pb.minimum_quantity) }}</td>
                            <td class="">
                              <div class="d-flex fill-width justify-center">
                                <v-btn color="red" depressed dark x-small class="mr-1" @click.prevent="REMOVE_PBJ(iPb)">
                                  <v-icon x-small>
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                                <v-btn color="blue" depressed dark x-small class="ml-1" @click.prevent="SYNC_PBJ(pb, iPb)">
                                  <v-icon x-small>
                                    mdi-pencil
                                  </v-icon>
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red"
            :disabled="loadingItem"
            small
            text
            dark
            class="rounded-0 text-capitalize"
            @click.prevent="dialogProductItem.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="$role(['director', 'logistic'], $store.state.user.role)"
            :disabled="pBerjenjang.indexSelected > -1"
            :loading="loadingItem"
            color="indigo darken-2"
            small
            depressed
            dark
            class="rounded-0 text-capitalize"
            @click.prevent="loadingItem ? false : PRODUCT_ITEM_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPricing.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogPricing.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogPricing.data.id ? 'Update Pricing' : 'Create New Pricing' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-autocomplete
            v-model.number="dialogPricing.data.customer"
            :search-input.sync="customerQuery"
            :items="customerResult"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            autocomplete="none"
            append-inner-icon="mdi-magnify"
            class="mb-4 rounded-0"
            label="Select Customer"
          />
          <v-text-field
            v-model.number="dialogPricing.data.price"
            outlined
            dense
            hide-details
            prefix="Rp"
            label="Price"
            class="mb-4 rounded-0"
            @keypress="$NumberOnly"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="yellow darken-4"
            text
            class="text-capitalize font-weight-bold"
            @click="dialogPricing.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="parseInt(dialogPricing.data.id)"
            color="red darken-1"
            text
            class="text-capitalize caption"
            @click="ProcessPricing(true)"
          >
            Delete
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            class="text-capitalize font-weight-bold"
            @click="ProcessPricing()"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogStock.show"
      persistent
      scrollable
      max-width="450"
    >
      <v-card v-if="dialogStock.show" >
        <v-card-title class="body-1 justify-center">
          {{ dialogStock.product_spec_detail ? dialogStock.product_spec_detail.item_fullname : '' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: calc(100vh - 150px);">
          <v-row>
            <v-col cols="12">
              <div class="d-flex flex-column align-start font-weight-bold">
                Stock tersedia di Operation Unit
                <div class="caption fon-weight-regular">
                  Berasal dari Stock Request
                </div>
                <v-divider class="my-2 fill-width" />
                <!-- <v-btn
                  outlined
                  x-small
                  color="primary"
                  class="text-capitalize"
                  dark
                  @click.prevent="($store.state.user.role === 'operation_unit' ? (dialogSyncStock.store = parseInt($store.state.user.store)) : ''), SyncNewStock()"
                >
                  <v-icon class="mr-1" small>
                    mdi-plus
                  </v-icon>
                  Sync Stock
                </v-btn> -->
              </div>
              <div v-for="(sdo, iSdo) in dialogStock.stock_data_ou" :key="'ou-stock-' + iSdo" class="elevation-1 rounded-lg py-2 px-4 my-2">
                <div>
                  <div class="d-flex fill-width align-center justify-space-between">
                    <div class="caption grey--text">
                      <span class="primary--text font-weight-bold"># {{ sdo.store_name || '-' }}</span>
                      <div>
                        IN: {{ (parseInt(sdo.quantity_sum) || 0) }}, OUT: {{ ((parseInt(sdo.quantity_sum_out) || 0) + (parseInt(sdo.stock_out_trx) || 0)) }}
                      </div>
                    </div>
                    <strong>
                      Qty : {{ (parseInt(sdo.quantity_sum) || 0) - ((parseInt(sdo.quantity_sum_out) || 0) + (parseInt(sdo.stock_out_trx) || 0)) }}
                    </strong>
                  </div>
                  <div v-if="sdo.booked_list.length" class="grey lighten-4 rounded-lg px-2 py-2 caption my-1">
                    <div class="d-flex fill-width align-center justify-space-between">
                      <strong>
                        Booked Transaction
                      </strong>
                    </div>
                    <v-divider class="my-1" />
                    <div v-for="(bl, iBl) in sdo.booked_list" :key="'stock-ou-booked-list-' + iSdo + '-' + iBl" class="d-flex fill-width justify-space-between align-center">
                      <div>
                        {{ ('#RDMS/' + parseInt(bl.store || 0) + '/TRX/' + parseInt(bl.trx)) }}
                      </div>
                      <div>
                        {{ bl.quantity }}
                      </div>
                    </div>
                    <v-divider class="my-1" />
                    <div class="d-flex fill-width align-center justify-space-between">
                      <strong>
                        # All Items
                      </strong>
                      <strong>
                        {{ TotalBookedList(sdo.booked_list) }}
                      </strong>
                    </div>
                  </div>
                  <div class="pa-2 font-weight-bold text-center">
                    {{ ((parseInt(sdo.quantity_sum) || 0) - ((parseInt(sdo.quantity_sum_out) || 0) + (parseInt(sdo.stock_out_trx) || 0))) - TotalBookedList(sdo.booked_list) }}
                  </div>
                </div>
                <!-- <div class="d-flex fill-width align-center justify-end py-1">
                  <v-btn
                    :disabled="$store.state.user.role === 'operation_unit' && parseInt(sdo.store) !== parseInt($store.state.user.store)"
                    depressed
                    x-small
                    color="brown"
                    class="text-capitalize"
                    dark
                    @click.prevent="MoveStock('ou', sdo)"
                  >
                    Move Stock
                  </v-btn>
                </div> -->
              </div>
            </v-col>
            <v-col v-if="false" cols="12" class="col-sm-6">
              <div class="d-flex flex-column align-start font-weight-bold">
                Stock Transaksi (sedang di Booking)
                <div class="caption fon-weight-regular">
                  Integrasi stock pada transaksi/booking terproses
                </div>
                <v-divider class="my-2 fill-width" />
              </div>
              <div v-if="!dialogStock.stock_data_booked.length" class="pa-4 fill-width text-center red--text text--lighten-3 caption">
                Tidak ada data
              </div>
              <div v-for="(sdb, iSdb) in dialogStock.stock_data_booked" :key="'booked-stock-' + iSdb" class="elevation-1 rounded-lg py-2 px-4 my-2">
                <div class="d-flex fill-width align-center justify-space-between">
                  <div class="caption grey--text">
                    {{ sdb.store_name || 'No Name' }}
                    <div>
                      {{ ('#RDMS/' + parseInt(sdb.store || 0) + '/TRX/' + parseInt(sdb.trx)) }}
                    </div>
                  </div>
                  <span>
                    Qty : {{ sdb.quantity }}
                  </span>
                </div>
                <!-- <div class="d-flex fill-width align-center justify-end py-1">
                  <v-btn
                    :disabled="$store.state.user.role === 'operation_unit' && parseInt(sdb.store) !== parseInt($store.state.user.store)"
                    depressed
                    x-small
                    color="brown"
                    class="text-capitalize"
                    dark
                    @click.prevent="MoveStock('booking', sdb)"
                  >
                    Move Stock
                  </v-btn>
                </div> -->
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-4"
            text
            class="text-capitalize font-weight-bold"
            @click="PRODUCT_GET(tableSearch), dialogStock.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogMoveStock.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogMoveStock.data" >
        <v-card-title class="text-capitalize body-1 justify-center">
          Move Stock from {{ dialogMoveStock.type === 'booking' ? '#Booking/Order' : '#SafeStock' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-row no-gutters>
            <v-col cols="12">
              <div v-if="dialogMoveStock.type === 'booking'" class="font-weight-bold text-center mb-4">
                {{ ('#RDMS/' + parseInt(dialogMoveStock.data.store || 0) + '/TRX/' + parseInt(dialogMoveStock.data.trx)) }}
              </div>
              <div v-else class="font-weight-bold text-center mb-4">
                - {{ dialogMoveStock.data.store_name }} - #{{ dialogMoveStock.data.store }} -
              </div>
              <v-select
                v-model="dialogMoveStock.moveTo"
                :items="[
                  { name: 'Warehouse', value: 'warehouse' },
                  { name: 'Booking (Processed Order)', value: 'booked' },
                  { name: 'Safe Stock (Operations Unit)', value: 'safestock' }
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                label="Move To"
                class="mb-4"
              />
              <v-select
                v-if="dialogMoveStock.moveTo && dialogMoveStock.moveTo !== 'warehouse'"
                v-model.number="dialogMoveStock.moveToSelected"
                :items="dialogMoveStock.moveTo === 'safestock' ? $store.state.storeData.filter(r => parseInt(r.id) !== (parseInt(dialogMoveStock.data.store) || 0)) : dialogMoveStock.processedOrder.filter((r) => parseInt(r.id) !== (parseInt(dialogMoveStock.data.trx) || 0))"
                :label="dialogMoveStock.moveTo === 'safestock' ? 'Select Operations Unit' : 'Select Processed Order'"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
                class="mb-4"
              />
              <v-text-field
                v-model.number="dialogMoveStock.moveToQuantity"
                outlined
                dense
                hide-details
                :suffix="'From ' + dialogMoveStock.moveToQuantityTotal"
                prefix="Move"
                label="Quantity to Move"
                @keypress="$NumberOnly"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-4"
            text
            class="text-capitalize font-weight-bold"
            @click="dialogMoveStock.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            class="text-capitalize font-weight-bold"
            @click="dialogMoveStock.loading ? $store.dispatch('TOAST', { show: true, message: 'Please wait, update inprogress!!' }) : MoveStockProcess()"
          >
            Process
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSyncStock.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card >
        <v-card-title class="text-capitalize body-1 justify-center">
          Sync Stock Data
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-row no-gutters>
            <v-col cols="12">
              <v-select
                v-model.number="dialogSyncStock.store"
                :disabled="$store.state.user.role === 'operation_unit'"
                :items="$store.state.storeData"
                :label="'Select Operations Unit'"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
                class="mb-4"
              />
              <v-text-field
                v-model.number="dialogSyncStock.quantity"
                outlined
                dense
                hide-details
                label="Sync Quantity (jumlah stock aktual saat ini)"
                @keypress="$NumberOnly"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-4"
            text
            class="text-capitalize font-weight-bold"
            @click="dialogSyncStock.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            class="text-capitalize font-weight-bold"
            @click="dialogSyncStock.loading ? $store.dispatch('TOAST', { show: true, message: 'Please wait, update inprogress!!' }) : SyncNewStockProcess()"
          >
            Process
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogStockOURequest.show"
      persistent
      scrollable
      max-width="750"
    >
      <v-card>
        <v-card-title class="body-1 justify-space-between">
          Stock Request History
          <v-btn color="purple" class="text-capitalize" depressed small dark @click.prevent="StockRequestOUShowDetail()">
            + Create
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 90vh;">
          <v-select
            v-model.number="dialogStockOURequest.store"
            :disabled="$store.state.user.role === 'operation_unit'"
            :items="[{ id: 0, name: 'Warehouse' }].concat($store.state.storeData)"
            label="Select unit"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            class="mb-4"
            @change="LoadStockRequestOU()"
          />
          <div v-if="!dialogStockOURequest.data.length" class="pa-4 text-center grey--text">
            No items
          </div>
          <div v-for="(psor, iPsor) in dialogStockOURequest.data" :key="'stock-ou-request-' + iPsor" class="d-flex fill-width justify-space-between align-center mb-2 px-1">
            <div class="d-flex flex-column">
              <div :class="[psor.store === parseInt(dialogStockOURequest.store) ? 'purple--text' : 'red--text']" class="d-flex font-weight-bold align-center">
                {{ parseInt(psor.store) ? psor.store_name : 'Warehouse' }}
                <v-icon :color="psor.store === parseInt(dialogStockOURequest.store) ? 'purple' : 'red'" class="mx-1" small>
                  mdi-chevron-right
                </v-icon>
                {{ parseInt(psor.store2) ? psor.store2_name : 'Warehouse' }}
              </div>
              <div class="caption">
                Quantity : {{ parseInt(psor.items_quantity) }} from {{ psor.items_count }} items
              </div>
            </div>
            <div class="d-flex align-center">
              <div>
                {{ parseInt(psor.status) === 1 ? 'Processed' : (parseInt(psor.status) === 2 ? 'Received' : 'Requested') }}
              </div>
              <v-divider vertical class="mx-4" />
              <v-btn text color="purple" dark class="text-capitalize" small @click.prevent="StockRequestOUShowDetail(psor)">
                Detail
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="yellow darken-4"
            text
            class="text-capitalize font-weight-bold"
            @click="PRODUCT_GET(tableSearch), dialogStockOURequest.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <v-dialog
        v-model="dialogStockOURequest.detail.show"
        persistent
        scrollable
        max-width="450"
      >
        <v-card v-if="dialogStockOURequest.detail.data">
          <v-card-title class="body-1 justify-center">
            {{ parseInt(dialogStockOURequest.detail.data.id) ? 'Detail' : 'Create' }} Stock Request
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-4" style="max-height: 90vh;">
            <v-select
              v-model.number="dialogStockOURequest.detail.data.store"
              :disabled="$store.state.user.role === 'operation_unit' || parseInt(dialogStockOURequest.detail.data.status) === 2"
              :items="[{ id: 0, name: 'Warehouse' }].concat($store.state.storeData)"
              label="Permintaan Stock Dari :"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              class="mb-4"
              @change="dialogStockOURequest.detail.data.store2 = 0"
            />
            <v-select
              v-model.number="dialogStockOURequest.detail.data.store2"
              :disabled="parseInt(dialogStockOURequest.detail.data.status) === 2"
              :items="[{ id: 0, name: 'Warehouse' }].concat($store.state.storeData).filter(r => parseInt(r.id) !== parseInt(dialogStockOURequest.detail.data.store))"
              label="Meminta Stock Ke :"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-select
              v-if="parseInt(dialogStockOURequest.detail.data.id)"
              v-model.number="dialogStockOURequest.detail.data.status"
              :disabled="StockRequestStatusDisabled"
              :items="[{ id: 0, name: 'Requested' }, { id: 1, name: 'Processed' }, { id: 2, name: 'Received' }, { id: 99, name: 'Canceled' }].filter(r => parseInt(r.id) >= parseInt(dialogStockOURequest.detail.data.statusOld))"
              label="Status"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <div>
              <div>
                <v-divider class="my-2" />
                <div class="caption grey--text d-flex fill-width align-center">
                  <v-icon class="mr-2" x-small>
                    mdi-format-list-bulleted-square
                  </v-icon>
                  ITEMS
                </div>
                <v-divider class="mt-2" />
                <div v-if="parseInt(dialogStockOURequest.detail.data.status) !== 2" class="d-flex justify-space-between fill-width align-center grey lighten-3 px-2">
                  <div class="d-flex justify-space-between fill-width align-center my-2 pr-4">
                    <v-autocomplete
                      v-model="exploreProduct.selected"
                      :search-input.sync="exploreProduct.query"
                      :items="exploreProduct.result"
                      item-value="id"
                      item-text="product_fullname"
                      return-object
                      outlined
                      dense
                      hide-details
                      label="Add item"
                    />
                  </div>
                  <v-btn outlined color="purple" class="text-capitalize caption rounded" @click.prevent="AddStockRequestItem()">
                    <v-icon small>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </div>
                <v-divider class="mb-2" />
                <div v-if="!dialogStockOURequest.detail.data.items.length" class="pa-4 text-center red--text text--lighten-4">
                  No items
                </div>
                <div v-else class="fill-width pt-2">
                  <div v-for="(i, iI) in dialogStockOURequest.detail.data.items.filter(r => !parseInt(r.removed))" :key="'item-requested-' + iI" class="d-flex justify-space-between fill-width align-center px-3 grey lighten-4 rounded mb-2">
                    <div class="d-flex justify-space-between align-center my-2" style="width: calc(100% - 50px);">
                      <div style="width: calc(100% - 70px);">
                        {{ iI + 1 }}. {{ i.product_fullname }}
                      </div>
                      <span class="pl-3" style="width: 70px;">
                        <input v-model="dialogStockOURequest.detail.data.items[iI].quantity" type="text" class="px-2 py-1 d-inline-block rounded grey lighten-3 text-center" style="outline: none;max-width: 100%;" @keypress="$NumberOnly">
                      </span>
                    </div>
                    <v-icon v-if="parseInt(dialogStockOURequest.detail.data.status) !== 2" color="red" small style="width: 50px" @click.prevent="RemoveStockRequestItem(i)">
                      mdi-delete
                    </v-icon>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn
              color="yellow darken-4"
              text
              class="text-capitalize font-weight-bold"
              @click="dialogStockOURequest.detail.show = false"
            >
              Close
            </v-btn>
            <v-spacer />
            <v-btn
              :loading="dialogStockOURequest.detail.loading"
              :disabled="parseInt(dialogStockOURequest.detail.data.statusOld) === 2"
              color="primary"
              depressed
              class="text-capitalize px-6 font-weight-bold"
              @click="ProcessStockOURequest()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
let searchTimeout = null
let searchTimeoutCustomerQ = null
export default {
  name: 'Inventory',
  data: (vm) => ({
    customerQuery: '',
    customerResult: '',
    loadingDownload: false,
    customerSelected: 0,
    storeUnit: 0,
    loadingProcess: false,
    productStatus: 1,
    productType: 0,
    pBerjenjang: {
      type: 'discount',
      platform: '',
      customer: '',
      minimum_quantity: 1,
      discount: 0,
      gift: '',
      indexSelected: -1
    },
    loadingImageUpload: false,

    headers: [
      // { text: 'PRODUCT ID', value: 'id', sortable: true },
      { text: 'SKU', value: 'sku', sortable: false },
      {
        text: 'Product',
        value: 'name'
      },
      { text: 'Price', value: 'price', sortable: true },
      { text: 'Stock In', value: 'stock_in', sortable: true },
      { text: 'Stock Out', value: 'stock_out_trx', sortable: true },
      { text: 'Actual Stock', value: 'stock', sortable: true },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 50,
    tableSearchStatus: '',
    options: {},
    dialogProduct: {
      show: false,
      data: {
        id: null,
        is_service: 0,
        category: '',
        categorysub: '',
        name: '',
        description: '',
        galleries: [],
        best_seller: 0,
        featured: 0,
        stores: [],
        status: 0 // 0: Not Published, 1: Published, 100: Deleted
      },
      items: []
    },
    dialogProductItem: {
      show: false,
      pricing: [],
      data: {
        id: null,
        sku: '',
        sku2: '',
        product: 0,
        name: '',
        merk: '',
        price_initial: 0,
        price: 0,
        price_berjenjang: [],
        weight: 0,
        volume: 0,
        specs: '',
        galleries: [],
        featured: 0,
        status: 0
      },
      index: -1,
      direct: false
    },
    galleryIndexToChange: -1,
    loadingItem: false,
    dialogPricing: {
      show: false,
      data: null
    },
    dialogStock: {
      show: false,
      product_spec_detail: null,
      stock_data_ou: {},
      stock_data_booked: []
    },
    dialogMoveStock: {
      show: false,
      loading: false,
      type: '',
      moveTo: '',
      moveToSelected: 0,
      moveToQuantity: 0,
      moveToQuantityTotal: 0,
      moveToNotes: '',
      processedOrder: [],
      data: null
    },
    dialogSyncStock: {
      show: false,
      loading: false,
      store: 0,
      quantity: 0,
      product_spec: 0
    },
    dialogStockOURequest: {
      show: false,
      store: 0,
      data: [],
      total: 0,
      detail: {
        show: false,
        loading: false,
        data: null
      }
    },
    exploreProduct: {
      selected: null,
      query: '',
      result: []
    }
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    }),
    StockRequestStatusDisabled () {
      const data = this.dialogStockOURequest.detail.data
      const user = this.$store.state.user
      if (parseInt(data.statusOld) >= 2) {
        return true
      } else if (user.role === 'logistic') {
        return false
      } else if (user.store) {
        const isMyBranch = parseInt(data.store) === parseInt(user.store.id)
        if (!parseInt(data.statusOld)) {
          return isMyBranch
        } else if (parseInt(data.statusOld) === 1) {
          return !isMyBranch
        }
      }
      return true
    }
  },
  watch: {
    'dialogMoveStock.moveTo' () {
      this.moveToSelected = 0
    },
    customerQuery (v) {
      this.customerResult = []
      if (searchTimeoutCustomerQ) {
        clearTimeout(searchTimeoutCustomerQ)
      }
      if (v) {
        searchTimeoutCustomerQ = setTimeout(() => {
          this.CUSTOMER_GET(v)
          clearTimeout(searchTimeoutCustomerQ)
        }, 300)
      }
    },
    productType () {
      this.PRODUCT_GET(this.tableSearch)
    },
    productStatus () {
      this.PRODUCT_GET(this.tableSearch)
    },
    storeUnit () {
      this.PRODUCT_GET(this.tableSearch)
    },
    options: {
      handler () {
        this.PRODUCT_GET(this.tableSearch)
      },
      deep: true
    },
    storeActive (v) {
      if (v) {
        this.PRODUCT_GET(this.tableSearch)
      }
    },
    tableSearch: function (q) {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.PRODUCT_GET(q)
      }, 300)
    },
    'exploreProduct.query': function (q) {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.PRODUCT_SEARCH()
      }, 300)
    }
  },
  created () {
    // this.ExportExcel('all')
  },
  methods: {
    PRICE_LIST_DOWNLOAD () {
      if (this.loadingDownload) {
        this.$store.dispatch('TOAST', { show: true, message: 'Sedang proses download!' })
        return false
      }
      if (!confirm('Download Price List ' + (parseInt(this.customerSelected) ? 'Customer' : 'Default') + '?')) {
        return false
      }
      this.loadingDownload = true
      this.$store.dispatch('PRICE_LIST_DOWNLOAD', this.customerSelected)
        .then((res) => {
          this.loadingDownload = false
          if (res.status) {
            if (res.data.data.status) {
              const a = document.createElement('a')
              a.setAttribute('href', res.data.data.data)
              a.setAttribute('target', '_blank')
              // a.setAttribute('download', 'true')
              a.click()
            }
          }
        })
    },
    TotalBookedList (data) {
      let total = 0
      for (let t = 0; t < data.length; t++) {
        total += (parseInt(data[t].quantity) || 0)
      }
      return total
    },
    ProcessStockOURequest () {
      if (!confirm('Save data ?')) {
        return false
      }
      const data = Object.assign({}, this.dialogStockOURequest.detail.data)
      if (!data.items.filter(r => !parseInt(r.removed)).length) {
        this.$store.dispatch('TOAST', { show: true, message: 'No item selected!' })
        return false
      }
      if (!parseInt(data.store) && !parseInt(data.store2)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please set request "from" and "to"!' })
        return false
      }
      if (this.$store.state.user && this.$store.state.user.role === 'operation_unit' && parseInt(this.$store.state.user.store.id) !== parseInt(data.store)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Unauthorized requestor!' })
        return false
      }
      delete data.statusOld
      this.dialogStockOURequest.detail.loading = true
      this.$store.dispatch('product/STOCK_OU_HISTORY_PROCESS', data)
        .then(async (res) => {
          this.dialogStockOURequest.detail.loading = false
          if (res.status) {
            const response = await this.LoadStockRequestOU()
            if (response) {
              this.dialogStockOURequest.detail.show = false
            }
          }
        })
    },
    AddStockRequestItem () {
      if (this.exploreProduct.selected) {
        const selected = Object.assign({}, this.exploreProduct.selected)
        const isExist = this.dialogStockOURequest.detail.data.items.find(r => parseInt(r.product_spec) === parseInt(selected.id))
        if (!isExist) {
          this.dialogStockOURequest.detail.data.items.push({ product_spec: parseInt(selected.id), product_fullname: selected.item_fullname, quantity: 1, removed: 0 })
          this.exploreProduct.selected = null
        } else if (parseInt(isExist.id) && parseInt(isExist.removed)) {
          const findIndex = this.dialogStockOURequest.detail.data.items.findIndex(r => parseInt(r.id) === parseInt(isExist.id))
          if (findIndex > -1) {
            this.dialogStockOURequest.detail.data.items[findIndex].removed = 0
            this.exploreProduct.selected = null
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Invalid data!' })
          }
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Item already on the list!' })
        }
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'No item selected!' })
      }
    },
    RemoveStockRequestItem (item) {
      if (!confirm('Remove item ?')) {
        return false
      }
      const items = this.dialogStockOURequest.detail.data.items || []
      const itemsSelected = []
      for (let i = 0; i < items.length; i++) {
        if (parseInt(items[i].product_spec) !== parseInt(item.product_spec)) {
          itemsSelected.push(Object.assign({}, items[i]))
          console.log('1')
        } else if (parseInt(items[i].id)) {
          itemsSelected.push(Object.assign(items[i], { removed: 1 }))
        }
      }
      this.dialogStockOURequest.detail.data.items = itemsSelected || []
    },
    async PRODUCT_SEARCH () {
      const q = this.exploreProduct.query || ''
      await this.$store.dispatch('product/PRODUCT_GET', ('?q=' + (q || '') + '&page=1&limit=12&is_service=0')).then((res) => {
        if (res.status) {
          this.exploreProduct.result = res.data.data.data || []
        } else {
          this.exploreProduct.result = []
        }
      })
    },
    async StockRequestOUShow () {
      this.dialogStockOURequest.store = 0
      this.dialogStockOURequest.data = []
      if (this.$store.state.user && this.$store.state.user.role === 'operation_unit') {
        this.dialogStockOURequest.store = parseInt(this.$store.state.user.store.id) || 0
        if (!parseInt(this.dialogStockOURequest.store)) {
          this.$store.dispatch('TOAST', { show: true, message: 'Unauthorized!' })
          return false
        }
      }
      await this.LoadStockRequestOU()
      this.dialogStockOURequest.show = true
    },
    async LoadStockRequestOU () {
      let q = '?page=1&limit=25&sortBy=id&sort=DESC'
      q += '&store=' + this.dialogStockOURequest.store
      return await this.$store.dispatch('product/STOCK_OU_HISTORY', q)
        .then((res) => {
          if (res.status) {
            this.dialogStockOURequest.data = res.data.data.data || []
            this.dialogStockOURequest.total = parseInt(res.data.data.total) || 0
          }
          return res
        })
    },
    async StockRequestOUShowDetail (detail) {
      const user = this.$store.state.user || {}
      this.dialogStockOURequest.detail.data = detail ? Object.assign({}, detail) : {
        store: user.role === 'operation_unit' ? (parseInt(user.store.id) || 0) : 0,
        store2: null,
        status: 0,
        notes: '',
        items: []
      }
      this.dialogStockOURequest.detail.data.statusOld = parseInt(this.dialogStockOURequest.detail.data.status) || 0
      this.dialogStockOURequest.detail.show = true
    },
    async ExportExcel (isAll) {
      if (!isAll) {
        return false
      }
      const requestData = await this.PRODUCT_GET(this.tableSearch, null, isAll)
      if (requestData && requestData.status && requestData.data.data.data) {
        const headers = ['ID', 'SKU', 'PRODUCT', 'VARIANT', 'BRAND', 'BUY PRICE', 'SELL PRICE', 'TYPE', 'STATUS', 'CREATED']
        // const headers = ['ID', 'SKU', 'PRODUCT', 'VARIANT', 'BRAND', 'BUY PRICE', 'SELL PRICE', 'STOCK IN', 'STOCK OUT', 'STOCK OPS UNIT', 'STOCK WAREHOUSE', 'STOCK ACTUAL', 'TYPE', 'STATUS', 'CREATED']
        const rows = []
        const data = requestData.data.data.data || []
        for (let d = 0; d < data.length; d++) {
          const newRow = data[d]
          const isService = parseInt(newRow.product_is_service)
          rows.push([
            {
              type: Number,
              value: newRow.id
            },
            {
              type: String,
              value: newRow.sku || ''
            },
            {
              type: String,
              value: newRow.product_name || ''
            },
            {
              type: String,
              value: newRow.name || ''
            },
            {
              type: String,
              value: newRow.merk || ''
            },
            {
              type: Number,
              value: isService ? 0 : (parseFloat(newRow.price_initial) || 0)
            },
            {
              type: Number,
              value: parseFloat(newRow.price) || 0
            },
            {
              type: String,
              value: isService ? 'Service' : 'Parts'
            },
            {
              type: String,
              value: parseInt(newRow.status) ? 'Active' : 'Not Active'
            },
            {
              type: Date,
              format: 'yyyy-mm-dd hh:mm:ss',
              value: new Date(newRow.product_created)
            }
          ])
        }
        await this.$CreateExcelFile({
          headers: headers,
          rows: rows
        }, 'inventory-' + (isAll === 'all' ? 'all-' : 'filtered-') + this.$localDT(null, 'datedefault') + '.xlsx')
      }
    },
    SyncNewStock () {
      this.dialogSyncStock.store = 0
      this.dialogSyncStock.loading = false
      this.dialogSyncStock.quantity = 0
      this.dialogSyncStock.show = true
    },
    SyncNewStockProcess () {
      if (!this.dialogStock.product_spec_detail) {
        this.$store.dispatch('TOAST', { show: true, message: 'No product selected!' })
        return false
      } else if (!parseInt(this.dialogStock.product_spec_detail.id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Failed, no product selected!' })
        return false
      }
      if (!parseInt(this.dialogSyncStock.store)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No Ops Unit selected!' })
        return false
      }
      if (!parseInt(this.dialogSyncStock.quantity)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please add quantity!' })
        return false
      }
      if (!confirm('Sync new Safe Stock ?')) {
        return false
      }
      this.dialogSyncStock.loading = true
      const params = Object.assign({}, this.dialogSyncStock)
      params.product_spec = this.dialogStock.product_spec_detail.id
      delete params.show
      delete params.loading
      this.$store.dispatch('product/STOCK_MOVING_PROCESS', params)
        .then((res) => {
          this.dialogSyncStock.loading = false
          this.dialogSyncStock.show = false
          this.ShowStock(this.dialogStock.product_spec_detail)
        })
    },
    MoveStock (type, data) {
      this.dialogMoveStock.type = type
      this.dialogMoveStock.moveTo = ''
      this.dialogMoveStock.moveToSelected = 0
      this.dialogMoveStock.moveToQuantity = parseInt(data.quantity)
      this.dialogMoveStock.moveToQuantityTotal = parseInt(data.quantity)
      this.dialogMoveStock.moveToNotes = ''
      this.dialogMoveStock.data = Object.assign({}, data)
      this.MoveStockPrepareData()
      this.dialogMoveStock.show = true
    },
    MoveStockProcess () {
      if (!confirm('Process moving stock ?')) {
        return false
      }
      const moveStock = Object.assign({}, this.dialogMoveStock)
      if (!moveStock.data) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Selected Data!' })
        return false
      } else if (!parseInt(moveStock.data.id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Selected ID!' })
        return false
      }
      if (!moveStock.moveTo) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Move To!' })
        return false
      }
      if (!parseInt(moveStock.moveToSelected) && this.dialogMoveStock.moveTo !== 'warehouse') {
        this.$store.dispatch('TOAST', { show: true, message: 'Please select ' + (moveStock.type === 'booking' ? 'Booked Order!' : 'Operations Unit!') })
        return false
      }
      if (!parseInt(moveStock.moveToQuantity)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid quantity!' })
        return false
      } else if (parseInt(moveStock.moveToQuantity) > parseInt(moveStock.moveToQuantityTotal)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Quantity can\'t bigger than available stock!' })
        return false
      }
      this.dialogMoveStock.loading = true
      this.$store.dispatch('product/STOCK_MOVE_PROCESS', moveStock)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
            this.ShowStock(this.dialogStock.product_spec_detail)
            this.dialogMoveStock.show = false
          }
          this.dialogMoveStock.loading = false
        })
    },
    async MoveStockPrepareData () {
      this.dialogMoveStock.processedOrder = []
      await this.$store.dispatch('trx/TRX_GET', '?status=1&limit=1000').then((res) => {
        if (res.status) {
          this.dialogMoveStock.processedOrder = (res.data.data.data || []).map((r) => {
            r.name = ('#RDMS/' + parseInt(r.store || 0) + '/TRX/' + parseInt(r.id))
            return r
          })
        }
      })
      await this.$store.dispatch('trx/TRX_GET', '?status=2&limit=1000').then((res) => {
        if (res.status) {
          (res.data.data.data || []).map((r) => {
            r.name = ('#RDMS/' + parseInt(r.store || 0) + '/TRX/' + parseInt(r.id))
            this.dialogMoveStock.processedOrder.push(r)
          })
        }
      })
    },
    async ShowStock (productSpecDetail) {
      this.dialogStock.stock_data_booked = []
      this.dialogStock.stock_data_ou = {}
      this.dialogStock.product_spec_detail = Object.assign({}, productSpecDetail)
      await this.$store.dispatch('product/STOCK_MOVING_GET', { product_spec: productSpecDetail.id, type: 'ou' })
        .then((res) => {
          if (res.status) {
            const data = res.data.data || []
            const newData = {}
            for (let u = 0; u < data.length; u++) {
              const x = Object.assign({}, data[u])
              const isWithWarehouse = parseInt(x.store) ? (((parseInt(x.store) || 0) + (parseInt(x.store2) || 0)) === parseInt(x.store)) : (((parseInt(x.store) || 0) + (parseInt(x.store2) || 0)) === parseInt(x.store2))
              if (isWithWarehouse) {
                const qtyIN = parseInt(x.store) ? (parseInt(x.quantity_sum) || 0) : 0
                const qtyOUT = parseInt(x.store) ? 0 : (parseInt(x.quantity_sum) || 0)
                const store = parseInt(x.store) || parseInt(x.store2)
                newData[store] = newData[store] || { quantity_sum: 0, quantity_sum_out: 0 }
                newData[store].store_name = x.store_name
                newData[store].quantity_sum += qtyIN
                newData[store].quantity_sum_out += qtyOUT
                newData[store].stock_out_trx = (parseInt(x.stock_out_trx) || 0)
                newData[store].booked_list = []
              } else {
                const store = parseInt(x.store)
                newData[store] = newData[store] || { quantity_sum: 0, quantity_sum_out: 0 }
                newData[store].store_name = x.store_name
                newData[store].quantity_sum += (parseInt(x.quantity_sum) || 0)
                newData[store].stock_out_trx = (parseInt(x.stock_out_trx) || 0)
                newData[store].booked_list = []

                const store2 = parseInt(x.store2)
                newData[store2] = newData[store2] || { quantity_sum: 0, quantity_sum_out: 0 }
                newData[store2].store_name = x.store_name2
                newData[store2].quantity_sum_out += (parseInt(x.quantity_sum) || 0)
                newData[store2].booked_list = []
              }
            }

            this.dialogStock.stock_data_ou = newData
          }
        })
      await this.$store.dispatch('product/STOCK_MOVING_GET', { product_spec: productSpecDetail.id, type: 'booking' })
        .then((res) => {
          if (res.status) {
            this.dialogStock.stock_data_booked = res.data.data || []
            for (let x = 0; x < res.data.data.length; x++) {
              const d = res.data.data[x]
              const isExistStore = this.dialogStock.stock_data_ou[parseInt(d.store)]
              console.log(isExistStore)
              if (isExistStore) {
                this.dialogStock.stock_data_ou[parseInt(d.store)].booked_list.push(d)
              }
            }
          }
        })
      this.dialogStock.show = true
    },
    ShowPricing (d) {
      this.customerQuery = ''
      this.customerResult = []
      this.dialogPricing.data = Object.assign({
        id: null,
        customer: 0,
        product_spec: this.dialogProductItem.data.id,
        price: 0
      }, d)
      if (d) {
        if (parseInt(d.customer)) {
          this.customerResult.push({
            id: parseInt(d.customer),
            name: d.customer_name,
            phone: d.customer_phone,
            email: d.customer_email,
            role: d.customer_role
          })
        }
      }
      this.dialogPricing.show = true
    },
    ProcessPricing (isDelete) {
      if (isDelete) {
        if (!confirm('Delete pricing ?')) {
          return false
        }
        if (parseInt(this.dialogPricing.data.id)) {
          this.$store.dispatch('product/PRODUCT_ITEM_PRICING_DELETE', { customer: this.dialogPricing.data.customer, itemid: this.dialogPricing.data.product_spec })
            .then((res) => {
              if (res.status) {
                this.$store.dispatch('product/PRODUCT_ITEM_PRICING', this.dialogProductItem.data.id)
                  .then((res) => {
                    if (res.status) {
                      this.dialogProductItem.pricing = res.data.data || []
                    }
                  })
                this.$store.dispatch('TOAST', { show: true, message: 'Terhapus!' })
                this.dialogPricing.show = false
              } else {
                this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
              }
            })
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Invalid Pricing ID!' })
        }
      } else {
        if (parseInt(this.dialogPricing.data.customer)) {
          this.$store.dispatch('product/PRODUCT_ITEM_PRICING_PROCESS', this.dialogPricing.data)
            .then((res) => {
              if (res.status) {
                this.$store.dispatch('product/PRODUCT_ITEM_PRICING', this.dialogProductItem.data.id)
                  .then((res) => {
                    if (res.status) {
                      this.dialogProductItem.pricing = res.data.data || []
                    }
                  })
                this.$store.dispatch('TOAST', { show: true, message: 'Berhasil!' })
                this.dialogPricing.show = false
              }
            })
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Customer belum dipilih!' })
        }
      }
    },
    SYNC_PBJ (data, index) {
      const updated = data ? Object.assign({}, data) : null
      this.pBerjenjang.type = updated ? updated.type || 'discount' : 'discount'
      this.pBerjenjang.platform = updated ? parseInt(updated.customer) === 0 ? 0 : parseInt(updated.customer) === 1 ? 1 : '' : ''
      this.pBerjenjang.customer = updated ? parseInt(updated.customer) === 0 ? 0 : parseInt(updated.customer) === 1 ? 1 : '' : ''
      this.pBerjenjang.minimum_quantity = updated ? parseInt(updated.minimum_quantity) || 0 : 0
      this.pBerjenjang.discount = updated ? parseInt(updated.discount) || 0 : 0
      this.pBerjenjang.gift = updated ? updated.gift || '' : ''
      this.pBerjenjang.indexSelected = updated && (index || index === 0) ? index : -1
    },
    PROSES_PBJ () {
      const pbj = Object.assign({}, this.pBerjenjang)
      if (pbj.type !== 'discount' && pbj.type !== 'gift') {
        this.$store.dispatch('TOAST', { show: true, message: 'Type belum ditentukan!' })
        return false
      } else if (pbj.platform !== '' && pbj.platform !== 0 && pbj.platform !== 1) {
        this.$store.dispatch('TOAST', { show: true, message: 'Platform belum ditentukan!' })
        return false
      } else if (pbj.customer !== '' && pbj.customer !== 0 && pbj.customer !== 1) {
        this.$store.dispatch('TOAST', { show: true, message: 'Jenis Customer belum ditentukan!' })
        return false
      } else if (isNaN(parseInt(pbj.minimum_quantity)) || (parseInt(pbj.minimum_quantity) < 0)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tentukan minimum quantity!' })
        return false
      } else if (pbj.type === 'discount' && !parseInt(pbj.discount)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tentukan jumlah discount!' })
        return false
      } else if (pbj.type === 'gift' && !pbj.gift) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tentukan keterangan Gift!' })
        return false
      }
      delete pbj.indexSelected
      if (this.pBerjenjang.indexSelected > -1) {
        this.dialogProductItem.data.price_berjenjang[this.pBerjenjang.indexSelected] = pbj
      } else {
        this.dialogProductItem.data.price_berjenjang.push(pbj)
      }
      setTimeout(() => {
        this.dialogProductItem.data.price_berjenjang = this.$sortArray(this.dialogProductItem.data.price_berjenjang, 'minimum_quantity')
        this.SYNC_PBJ()
      }, 150)
    },
    REMOVE_PBJ (index) {
      if (!confirm('Apakah anda yakin ?')) {
        return false
      }
      this.dialogProductItem.data.price_berjenjang.splice(index, 1)
      this.SYNC_PBJ()
    },
    PRODUCT_ITEM_VIEW (i, index, directUpdate) {
      this.SYNC_PBJ()
      this.dialogProductItem.pricing = []
      if (i || index > -1) {
        const item = Object.assign({}, i)
        item.tags = !item.tags || typeof item.tags === 'string' ? this.$StrToArray(item.tags) : item.tags
        item.galleries = !item.galleries || typeof item.galleries === 'string' ? this.$StrToArray(item.galleries) : item.galleries
        item.price_berjenjang = !item.price_berjenjang || typeof item.price_berjenjang === 'string' ? this.$StrToArray(item.price_berjenjang) || [] : item.price_berjenjang
        item.price_berjenjang = item.price_berjenjang ? this.$sortArray(item.price_berjenjang, 'minimum_quantity') : []
        this.$store.dispatch('product/PRODUCT_ITEM_PRICING', item.id)
          .then((res) => {
            if (res.status) {
              this.dialogProductItem.pricing = res.data.data || []
            }
          })
        this.dialogProductItem.data = item
      } else {
        this.dialogProductItem.data = Object.assign({}, {
          id: null,
          sku: '',
          sku2: '',
          product: 0,
          name: '',
          merk: '',
          description: '',
          price_initial: 0,
          price: 0,
          price_berjenjang: [],
          weight: 0,
          volume: 0,
          specs: '',
          galleries: [],
          tags: [],
          status: 1
        })
      }
      this.dialogProductItem.direct = directUpdate || false
      this.dialogProductItem.index = index
      this.dialogProductItem.show = true
    },
    PRODUCT_ITEM_PROCESS () {
      if (this.dialogProductItem.direct) {
        const params = Object.assign({}, this.dialogProductItem.data)
        params.galleries = this.$ArrayToStr(params.galleries)
        params.tags = this.$ArrayToStr(params.tags)
        params.price_berjenjang = params.price_berjenjang.map((r) => {
          r = (typeof r === 'string') ? this.$StrToJson(r) : r
          r.minimum_quantity = parseInt(r.minimum_quantity) || 0
          return r
        })
        params.price_berjenjang = this.$ArrayToStr(params.price_berjenjang)
        this.loadingItem = true
        this.$store.dispatch('product/PRODUCT_ITEM_PROCESS', params)
          .then((res) => {
            if (res.status) {
              this.$store.dispatch('TOAST', { show: true, message: this.dialogProductItem.data.id ? 'Berhasil di ubah!' : 'Berhasil di simpan!' })
              this.PRODUCT_GET(this.tableSearch)
              this.dialogProductItem.show = false
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
            }
            this.loadingItem = false
          })
      } else {
        if (this.dialogProductItem.index > -1) {
          // this.dialogProduct.items[this.dialogProductItem.index] = Object.assign({}, this.dialogProductItem.data)
          const params = Object.assign({}, this.dialogProductItem.data)
          params.galleries = this.$ArrayToStr(params.galleries)
          params.tags = this.$ArrayToStr(params.tags)
          params.price_berjenjang = params.price_berjenjang.map((r) => {
            r = typeof r === 'string' ? this.$StrToJson(r) : r
            r.minimum_quantity = parseInt(r.minimum_quantity) || 0
            return r
          })
          params.price_berjenjang = this.$ArrayToStr(params.price_berjenjang)
          this.dialogProduct.items[this.dialogProductItem.index] = params
        } else {
          // this.dialogProduct.items.push(this.dialogProductItem.data)
          const params = Object.assign({}, this.dialogProductItem.data)
          params.galleries = this.$ArrayToStr(params.galleries)
          params.tags = this.$ArrayToStr(params.tags)
          params.price_berjenjang = this.$ArrayToStr(params.price_berjenjang)
          this.dialogProduct.items.push(params)
        }
        this.dialogProductItem.show = false
      }
    },
    async PRODUCT_VIEW (p) {
      let product = {
        id: null,
        is_service: 0,
        category: 'No Brand',
        categorysub: '',
        name: '',
        description: '',
        galleries: [],
        stores: [],
        best_seller: 0,
        featured: 0,
        items: [],
        tags: [],
        status: 1
      }
      this.dialogProduct.items = []
      if (p) {
        product = Object.assign({}, p)
        if (!product.category) {
          product.category = 'No Brand'
        }
        product.stores = product.stores ? this.$StrToArray(product.stores) : []
        product.tags = this.$StrToArray(product.tags)
        product.galleries = this.$StrToArray(product.galleries)
        await this.$store.dispatch('product/PRODUCT_GET_ONE', p.product)
          .then((res) => {
            if (res.status) {
              product = Object.assign({}, res.data.data)
              product.stores = product.stores ? this.$StrToArray(product.stores) : []
              product.galleries = this.$StrToArray(product.galleries)
              product.tags = this.$StrToArray(product.tags)
              this.dialogProduct.items = product.items.map(r => {
                // r.galleries = this.$StrToArray(r.galleries)
                // r.tags = this.$StrToArray(r.tags)
                return r
              })
              delete product.items
            }
          })
      }
      product.is_service = parseInt(this.productType) || 0
      this.dialogProduct.data = product
      this.dialogProduct.show = true
    },
    CUSTOMER_GET (q) {
      this.$store.dispatch('customer/CUSTOMER_GET', ('?q=' + (q || ''))).then((res) => {
        if (res.status) {
          this.customerResult = res.data.data || []
        } else {
          this.customerResult = []
        }
      })
    },
    PRODUCT_PROCESS () {
      if (!this.dialogProduct.data.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama produk harus di isi!' })
        return false
      }
      if (!this.dialogProduct.data.description) {
        this.$store.dispatch('TOAST', { show: true, message: 'Keterangan produk harus di isi!' })
        return false
      }
      const params = {
        product: Object.assign({}, this.dialogProduct.data),
        items: this.dialogProduct.items || []
      }
      if (params.product.category === 'No Brand') {
        params.product.category = ''
      }
      params.product.stores = params.product.stores ? params.product.stores.length ? this.$ArrayToStr(params.product.stores) : null : null
      params.product.tags = this.$ArrayToStr(params.product.tags)
      params.product.galleries = this.$ArrayToStr(params.product.galleries)
      if (!params.items.length) {
        this.$store.dispatch('TOAST', { show: true, message: 'Belum ada item ditambahkan!' })
        return false
      } else {
        params.items.map(r => {
          // r.price_berjenjang = (!r.price_berjenjang || typeof r.price_berjenjang === 'string') ? r.price_berjenjang : this.$ArrayToStr(r.price_berjenjang)
          // r.price_berjenjang = this.$ArrayToStr(r.price_berjenjang)
          // r.galleries = this.$ArrayToStr(r.galleries)
          // r.tags = this.$ArrayToStr(r.tags)
          return r
        })
      }
      this.loadingProcess = true
      this.$store.dispatch('product/PRODUCT_PROCESS', params)
        .then((res) => {
          this.loadingProcess = false
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil!' })
            this.PRODUCT_GET(this.tableSearch || '')
            this.dialogProduct.show = false
          }
        })
    },
    async PRODUCT_GET (q, searchOnly, dataExcel) {
      if (this.$store.state.user.role === 'operation_unit') {
        if (parseInt(this.$store.state.user.store.id)) {
          if (this.storeUnit !== parseInt(this.$store.state.user.store.id)) {
            this.storeUnit = parseInt(this.$store.state.user.store.id)
          }
        } else {
          return false
        }
      }
      let withType = ''
      if (parseInt(this.productType) > -1) {
        withType = '&is_service=' + parseInt(this.productType)
      }
      let withStatus = ''
      if (parseInt(this.productStatus) === 0 || parseInt(this.productStatus) === 1) {
        withStatus = '&status=' + parseInt(this.productStatus)
      }
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const withSortBy = sortBy ? (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : ''
      const pageRow = (parseInt(page) > 0 ? '&page=' + page : '')
      const limitRow = (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '')

      if (searchOnly) {
        await this.$store.dispatch('product/PRODUCT_GET', ('?store=' + (parseInt(this.storeUnit) || 0)) + '&q=' + (q || '') + '&page=1&limit=12&status=1&is_service=0').then((res) => {
          if (res.status) {
            this.dialogStockRequestItem.result = res.data.data.data || []
          } else {
            this.dialogStockRequestItem.result = []
          }
        })
      } else if (dataExcel) {
        const exportPage = '&page=1'
        const exportLimit = dataExcel === 'all' ? '&limit=1000000000' : limitRow
        const query = '?c=' + ('&store=' + (parseInt(this.storeUnit) || 0)) + exportPage + exportLimit + withSortBy
        const exportQuery = dataExcel === 'all' ? '' : (query + (q ? ('&q=' + q) : '') + withType + withStatus)
        return await this.$store.dispatch('product/PRODUCT_GET', exportQuery).then(res => res)
      } else {
        const query = '?c=' + ('&store=' + (parseInt(this.storeUnit) || 0)) + pageRow + limitRow + withSortBy
        this.tableLoading = true
        await this.$store.dispatch('product/PRODUCT_GET', query + (q ? ('&q=' + q) : '') + withType + withStatus).then((res) => {
          if (res.status) {
            this.table = res.data.data.data
            this.tableTotal = parseInt(res.data.data.total) || 0
          } else {
            this.table = []
            this.tableTotal = 0
          }
          this.tableLoading = false
        })
      }
    },
    changeGallery (index) {
      if (!confirm('Ganti image ?')) {
        return false
      }
      this.galleryIndexToChange = index
      this.UploadImage()
    },
    removeGallery (index) {
      if (!confirm('Apakah yakin akan dihapus ?')) {
        return false
      }
      this.dialogProductItem.data.galleries.splice(index, 1)
    },
    ImportXlsx () {
      if (!confirm('Apakah anda yakin? Mengimport data akan menghapus data produk yang ada termasuk data transaksi, stock, dll!')) {
        return false
      }
      if (!this.loadingImageUpload) {
        this.galleryIndexToChange = -1
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.xlsx')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e, 'xlsx')
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    UploadImage () {
      if (!this.loadingImageUpload) {
        this.galleryIndexToChange = -1
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event, type) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: type === 'xlsx' ? 'doc' : 'image'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                if (type === 'xlsx') {
                  $.$store.dispatch('TOAST', { show: true, message: 'Berhasil di import!' })
                  $.$store.dispatch('product/PRODUCT_TAGS_GET')
                  $.PRODUCT_GET($.tableSearch || '')
                } else if ($.galleryIndexToChange > -1) {
                  $.dialogProductItem.data.galleries[$.galleryIndexToChange] = resUpload.data.data.secure_url
                } else {
                  $.dialogProductItem.data.galleries.push(resUpload.data.data.secure_url)
                }
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.gmj-gallery {
  >.gmj-gallery-action {
    transition: opacity .3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    >.gmj-gallery-action {
      visibility: visible;
      pointer-events: all;
      opacity: 1;
    }
  }
}
.table-pbj {
  position: relative;
  min-width: 300px;
  overflow-x: auto;
  .pb-active {
    td {
      background: rgba(1, 4, 182, 0.1);
    }
  }
  td {
    padding: 2px 4px;
  }
  thead {
    td {
      padding: 4px;
      text-align: center;
      font-size: 10px;
      font-weight: bold;
    }
  }
  tbody {
    td {
      border-bottom: solid 1px rgba(0, 0, 0, .1);
    }
  }
}
</style>
